@import url(https://fonts.googleapis.com/css?family=Raleway:300,400);
@import url(https://fonts.googleapis.com/css?family=BenchNine:400,700);
@import url(https://fonts.googleapis.com/css?family=Russo+One:400);
@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap);
:root {
  --color-primary: rgb(216,222,240);
  --color-primary-rgb: 22,40,79;
  --color-primary-light: #018dc0; 
  --color-secondary: #ce1f43;
  --color-tertiary: #7bb2dd;
 

  --color-grey-light-1: #dbe0e2;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999; 

  --shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
  --shadow-light: 0 2rem 5rem rgba(0,0,0,.06);


  --line: 2px solid var(--color-primary-light);

}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

